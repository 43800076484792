<template>
  <div>
    <h1>User Profile</h1>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-card>
      <v-card-text>
        <v-form @submit.prevent="save">
          <h3>Personal Details</h3>
          <v-row>
            <v-col>
              <v-text-field
                v-model="user.firstName"
                label="First Name"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="user.lastName"
                label="Last Name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="user.telNumber"
                label="Mobile Number"
              ></v-text-field>
            </v-col>
            <v-col></v-col>
          </v-row>
          <h3>Physical Address</h3>
          <v-row>
            <v-col>
              <v-text-field
                v-model="user.location.addy1"
                label="Address 1"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="user.location.addy2"
                label="Address 2"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="user.location.suburb"
                label="Suburb"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="user.location.city"
                label="City"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="user.location.country"
                label="Country"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="user.location.postcode"
                label="postcode"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn color="success" @click="save">Save</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";

export default {
  data() {
    return {
      isLoading: false,
      user: new User(),
    };
  },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            firstName
            lastName
            telNumber
            location
          }
        }
      `,
      fetchPolicy: "no-cache",
      result() {
        const keys = Object.keys(this.user.location);
        if (this.me.location) {
          for (const key of keys) {
            this.user.location[key] = this.me.location[key];
          }
        }

        this.user.firstName = this.me.firstName;
        this.user.lastName = this.me.lastName;
        this.user.telNumber = this.me.telNumber;
      },
    },
  },
  methods: {
    async save() {
      this.isLoading = true;
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation updateUser($user: UserUpdateInput!) {
              updateUser(user: $user) {
                id
                updated
              }
            }
          `,
          variables: {
            user: {
              id: self.me.id,
              firstName: self.user.firstName,
              lastName: self.user.lastName,
              telNumber: self.user.telNumber,
              location: self.user.location,
              fullAddress: self.user.fullAddress,
            },
          },
        });

        if (response?.data?.updateUser?.updated) {
          this.$swal({
            title: "Success",
            text: "Profile details was successfully updated",
            icon: "success",
            confirmButtonColor: "#c79477",
          });
        } else {
          this.$swal({
            title: "Error",
            text: "Something went wrong, please try again later",
            icon: "error",
            confirmButtonColor: "#c79477",
          });
        }

        this.isLoading = false;
      } catch (e) {
        this.$swal({
          title: "Error",
          text: e.message,
          icon: "error",
          confirmButtonColor: "#c79477",
        });
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
